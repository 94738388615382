<div>
  <div class="sub-header">
    <span>
      <button mat-icon-button aria-label="Go Back" (click)="onGoBack()">
        <mat-icon class="back-icon" aria-hidden="false" aria-label="Go Back">chevron_left</mat-icon>
      </button>
    </span>
    <span class="user-name">
      Add New User
    </span>
  </div>
  <div class="sub-content spec-section">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit($event)">
          <div class="row-section">
            <label>First Name</label>
            <mat-form-field>
              <input type="text" matInput placeholder="First Name" formControlName="firstname">
              <mat-error
                *ngIf="this.registerForm.get('firstname')?.touched && this.registerForm.get('firstname')?.hasError('required')">
                First Name is required</mat-error>
            </mat-form-field>
          </div>

          <div class="row-section">
            <label>Last Name</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Last Name" formControlName="lastname">
              <mat-error
                *ngIf="this.registerForm.get('lastname')?.touched && this.registerForm.get('lastname')?.hasError('required')">
                Last Name is required</mat-error>
            </mat-form-field>
          </div>

          <div class="row-section">
            <label>Email</label>
            <mat-form-field>
              <input type="email" matInput placeholder="Email" formControlName="email">
              <!-- Here we can display error messages/hints for the user, if one of the Validators adds an error to the email 
                   with the .touched check we only display the hints if the input was touched by the users -->
              <mat-error
                *ngIf="this.registerForm.get('email')?.touched && this.registerForm.get('email')?.hasError('required')">
                Email is required</mat-error>
              <mat-error
                *ngIf="this.registerForm.get('email')?.touched && this.registerForm.get('email')?.hasError('email')">
                Email must be a valid Email</mat-error>
            </mat-form-field>
          </div>

          <label id="example-radio-group-label">Select Gender</label>
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            formControlName="gender">
            <mat-radio-button value="m">Male</mat-radio-button>
            <mat-radio-button value="f">Female</mat-radio-button>
          </mat-radio-group>

          <div class="row-section">
            <label>Birth Date</label>
            <mat-form-field>
              <mat-label>Birth Date</mat-label>
              <input matInput [matDatepicker]="picker" placeholder="MM/DD/YYYY" formControlName="birthDate"
                [min]="minStartDate" [max]="maxBirthDate" (dateChange)="dateChangeHandler($event.value)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [disabled]="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row-section">
            <label>Mobile Number</label>
            <div class="mobile_number_div">
              <mat-form-field>
                <mat-label>Select Country</mat-label>
                <mat-select (valueChange)="selectCountry($event)" [(value)]="selectedCountry">
                  <mat-option value="US" >USA</mat-option>
                  <!-- <mat-option value="CA">Canada</mat-option>
                  <mat-option value="IN">India</mat-option> -->
                </mat-select>
              </mat-form-field>
          
              <mat-form-field class="mobileNumber">
                <input matInput placeholder="Mobile Phone" formControlName="mobilephone" type="number">
                <mat-error
                  *ngIf="this.registerForm.get('mobilephone')?.touched && this.registerForm.get('mobilephone')?.hasError('required')">
                  Mobile Number is required</mat-error>
                  <mat-error
                  *ngIf="this.registerForm.get('mobilephone')?.hasError('message')">
                  {{this.registerForm.get('mobilephone').errors?.message}}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-section">
            <label>Address</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Address" formControlName="address">
              <mat-error
                *ngIf="this.registerForm.get('address')?.touched && this.registerForm.get('address')?.hasError('required')">
                Address is required</mat-error>
            </mat-form-field>
          </div>
            <mat-form-field style="float: right;">
              <input type="text" matInput placeholder="Apt Suite" formControlName="aptsuite">
              <mat-error
                *ngIf="this.registerForm.get('aptsuite')?.touched && this.registerForm.get('aptsuite')?.hasError('required')">
                Apt Suite is required</mat-error>
            </mat-form-field>
            <mat-form-field style="float: right;">
              <input type="text" matInput placeholder="City" formControlName="city">
              <mat-error
                *ngIf="this.registerForm.get('city')?.touched && this.registerForm.get('city')?.hasError('required')">
                City is required</mat-error>
            </mat-form-field>
            <mat-form-field style="float: right;">
              <mat-label>State</mat-label>
              <mat-select name="state" formControlName="state">
                <mat-option value="">None</mat-option>
                <mat-option *ngFor="let data of selectData" [value]="data.stateCode">
                  {{data.stateName}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="this.registerForm.get('state')?.touched && this.registerForm.get('state')?.hasError('required')">
                State is required</mat-error>
            </mat-form-field>
            <mat-form-field style="float: right;">
              <input type="text" matInput placeholder="PostalCode" formControlName="postalcode">
              <mat-error
                *ngIf="this.registerForm.get('postalcode')?.touched && this.registerForm.get('postalcode')?.hasError('required')">
                PostalCode is required</mat-error>
            </mat-form-field>

            <div class="user-footer">
              <div class="error-required" *ngIf="isDisplayFieldsRequired">
                All Fields are required. Please fill all fields.
            </div>
              <button mat-button (click)="onGoBack()">Cancel</button>
              <button mat-flat-button color="primary" type="submit">Submit</button>
            </div>
        </form>
      </mat-card-content>

    </mat-card>
  </div>
</div>